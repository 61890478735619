#leads {
  .table_content {
    .top {
      padding: 46px 0 0 10px;

      .select {
        width: 308px;
        display: inline-block;
        margin-right: 45px;

        /deep/ .el-form-item {
          margin-bottom: 32px;
        }
      }

      .leads_item {
        float: right;
      }
    }

    .table_box_item {
      > li {
        width: 100%;
        display: none;

        &.active {
          display: block;
        }

        .table_box {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #leads .table_content .top {
    padding: 45px 24px 24px;

    .select {
      width: 100%;
      @include rtl-sass-prop-sd(margin-right, 0px);
      /deep/ .el-form-item {
        margin-bottom: 20px;
      }
    }
    .leads_item {
      li {
        min-width: 200px;
      }

      .white_button {
        @include rtl-sass-prop-sd(margin-right, 0px);
      }
    }
  }
}

@media (max-width: 550px) {
  #leads .table_content .top .leads_item li {
    width: 100%;
  }
}
